module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"proeprtyeportal","short_name":"proeprtyeportal","start_url":"/","background_color":"#ec0089","theme_color":"#ec0089","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-54LWD4J","includeInDevelopment":false,"gtmAuth":null,"gtmPreview":null,"dataLayerName":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
